import { mapGetters, mapActions } from 'vuex'
import { ADD_TOAST_MESSAGE } from 'vuex-toast'
import { uniqBy } from 'lodash'
import { StandardizeObjects } from '@cedro-technologies/people-vue-ui'
import IntentionsApi from '@/api/intention'
import ActiveTrainingApi from '@/api/activeTraining'

export default {
  data () {
    return {
      isLoading: false,
      height: 700,
      width: 1000,
      search: '',
      isSearch: false,
      dataResult: [],
      headersList: [
        {
          text: this.$t('activeTraining.expression'),
          value: 'expression',
          align: 'left',
          width: 'calc(100% - 650px)',
          isSort: true,
          externalSort: true
        },
        {
          text: this.$t('activeTraining.numberTimesFired'),
          value: 'occurrences',
          align: 'center',
          width: '350px',
          isSort: true,
          externalSort: true
        },
        {
          text: this.$t('activeTraining.intention'),
          value: 'actionSelect',
          align: 'center',
          width: '350px'
        }
      ],
      actionSelect: {
        label: this.$t('activeTraining.select'),
        value: 'id',
        text: 'intent',
        list: 'suggestions',
        setSelected: 'id',
        enableDelete: true,
        alternativeEditIcon: true,
        editTooltip: this.$t('activeTraining.linkTooltip'),
        autoComplete: true
      },
      paginationData: {
        show: false,
        pagesCount: 1,
        totalVisible: 6,
        currentPage: 1,
        ordinationType: 'desc',
        ordinationField: 'occurrences'
      },
      expressionSelect: {},
      size: 18
    }
  },
  computed: {
    ...mapGetters([
      'getterSelectedBot',
      'getterSelectedCompany'
    ])
  },
  created () {
    this.height = document.scrollingElement.offsetHeight - 215
    this.width = document.scrollingElement.offsetWidth
  },
  mounted () {
    window.addEventListener('resize', () => {
      this.checkHeight()
    })
    this.getActiveTraining()
  },
  methods: {
    closeSearch () {
      this.search = ''
      this.isSearch = false
    },
    cleanCloseSearch () {
      if (this.search.length === 1 || this.search.length > 1024) {
        this.addToast({
          text: this.$t('activeTraining.toast.searchCharactersWarning'),
          type: 'warning'
        })

        return
      }

      if (!this.search) {
        this.isSearch = false
      }

      setTimeout(() => {
        this.getActiveTraining()
      }, 100)
    },
    togleIsSearch () {
      this.isSearch = !this.isSearch
    },
    checkHeight () {
      this.height = window.innerHeight - 215
      this.width = window.innerWidth
    },
    ...mapActions([
      'actionAddExpression'
    ]),
    ...mapActions({
      addToast: ADD_TOAST_MESSAGE
    }),
    getActiveTraining () {
      this.isLoading = true
      this.dataResult = []

      const params = {
        botId: this.getterSelectedBot.idBot,
        companyId: this.getterSelectedCompany.idCompany,
        page: this.search === '' ? this.paginationData.currentPage : 1,
        pageSize: this.size,
        sort: `${this.paginationData.ordinationType === 'asc' ? '' : '-'}${this.paginationData.ordinationField}`,
        expression: this.search
      }

      ActiveTrainingApi.getActiveTraining(params)
        .then((response) => {
          const formatedResponse = StandardizeObjects.standardizeObjects(response.data, true)

          this.dataResult = formatedResponse.data.map(item => ({
            ...item,
            idReport: item.id,
            suggestions: item.suggestions.map(suggestion => ({
              ...suggestion,
              intent: suggestion.intent.toLowerCase() === 'none' ? this.$t('activeTraining.unknown') : suggestion.intent
            }))
          }))

          this.paginationData.pagesCount = formatedResponse.pageCount
          this.paginationData.show = formatedResponse.pageCount > 1
          this.isLoading = false
        })
        .catch((e) => {
          console.error(e)
          this.dataResult = []
          this.addToast({
            text: this.$t('activeTraining.toast.errorFetching'),
            type: 'danger'
          })

          this.isLoading = false
        })
    },
    addExpression () {
      if (!this.expressionSelect.intentId) {
        this.addToast({
          text: 'Para vincular uma expressão à uma intenção, é necessário selecionar um intenção.',
          type: 'warning'
        })

        return
      }

      this.isLoading = true

      const newExpression = {
        idBot: this.getterSelectedBot.idBot,
        exampleText: this.expressionSelect.expressionName,
        nameIntent: this.expressionSelect.intentName,
        idIntent: this.expressionSelect.intentId,
        entity: [],
        entityLabels: []
      }

      this.actionAddExpression(newExpression)
        .then(() => {
          this.addToast({
            text: this.$t('activeTraining.toast.addExpression'),
            type: 'success'
          })

          const expressionIndex = this.dataResult.findIndex(item => item.id === this.expressionSelect.id)

          if (expressionIndex !== -1) {
            this.dataResult.splice(expressionIndex, 1)
          }

          this.expressionSelect = {}
          this.isLoading = false
        })
        .catch((e) => {
          if (e.response.status === 409) {
            this.addToast({
              text: this.$t('activeTraining.toast.recordAlreadyAdded'),
              type: 'warning'
            })
          } else {
            this.addToast({
              text: this.$t('activeTraining.toast.errorAdding'),
              type: 'danger'
            })
          }
          this.isLoading = false
        })
    },
    newExpressionSelect (expression) {
      if (expression.row) {
        const index = this.dataResult.findIndex((item) => item.id === expression.row.id)

        this.$set(this.dataResult, index, {
          ...expression.row,
          selectedIntention: expression.row.suggestions.find(item => item.id === expression.item)
        })

        this.expressionSelect = {
          expressionName: expression.row.expression,
          intentName: expression.row.suggestions.find(item => item.id === expression.item).intent,
          intentId: expression.row.suggestions.find(item => item.id === expression.item).id,
          id: expression.row.id
        }
      } else if (Object.keys(this.expressionSelect).length === 0 || this.expressionSelect.id !== expression.id) {
        this.expressionSelect = {
          expressionName: expression.expression,
          intentName: expression.hasOwnProperty('selectedIntention') ? expression.selectedIntention.intent : null,
          intentId: expression.hasOwnProperty('selectedIntention') ? expression.selectedIntention.id : null,
          id: expression.id
        }
      }
    },
    ordinationOrPagination (event, call) {
      switch (call) {
        case 'ordination':
          this.paginationData.ordinationType = event.ordinationType
          this.paginationData.ordinationField = event.ordinationField
          this.getActiveTraining()
          break
        case 'page':
          this.paginationData.currentPage = event
          this.getActiveTraining()
          break
        default:
          break
      }
    },
    deleteExpression () {
      this.isLoading = true

      const params = {
        botId: this.getterSelectedBot.idBot,
        companyId: this.getterSelectedCompany.idCompany,
        expressionId: this.expressionSelect.id
      }

      ActiveTrainingApi.deleteExpressionActiveTraining(params)
        .then(() => {
          this.addToast({
            text: this.$t('activeTraining.toast.deleteExpression'),
            type: 'success'
          })

          const expressionIndex = this.dataResult.findIndex(item => item.id === this.expressionSelect.id)

          if (expressionIndex !== -1) {
            this.dataResult.splice(expressionIndex, 1)
          }

          this.expressionSelect = {}
          this.isLoading = false
        })
        .catch(() => {
          this.addToast({
            text: this.$t('activeTraining.toast.errorDelete'),
            type: 'danger'
          })

          this.isLoading = false
        })
    },
    searchIntentions (searchText, itemSelected) {
      const index = this.dataResult.findIndex((item) => item.id === itemSelected.id)

      this.$set(this.dataResult, index, {
        ...itemSelected,
        isLoading: true
      })

      const params = {
        idBot: this.getterSelectedBot.idBot,
        page: 1,
        size: 20,
        searchString: searchText || ''
      }

      IntentionsApi.getAllIntentionsByIdBot(params.idBot, params.page, params.size, params.searchString)
        .then((response) => {
          const index = this.dataResult.findIndex((item) => item.id === itemSelected.id)
          const formatedResponse = response.data.map((item) => ({
            id: item.idIntent,
            intent: item.name
          }))
          let data = []
          data = itemSelected.suggestions.concat(formatedResponse)
          uniqBy(data, 'id')

          itemSelected.suggestions = data
          itemSelected.isLoading = false

          this.$set(this.dataResult, index, {
            ...itemSelected,
            suggestions: data,
            isLoading: false
          })
        })
        .catch((e) => {
          this.$set(this.dataResult, index, {
            ...itemSelected,
            isLoading: false
          })
          console.error(e)
        })
    }
  }
}
