import Const from '../helper/const'
import { custAxios } from './genericApi'

const getActiveTraining = (params) => {
  return custAxios().get(`${Const.SERVICE_BOT}/companies/${params.companyId}/bots/${params.botId}/active_training_expressions`, {
    params: {
      page: params.page,
      page_size: params.pageSize,
      sort: params.sort,
      expression: params.expression
    }
  })
}

const deleteExpressionActiveTraining = (params) => {
  return custAxios().delete(`${Const.SERVICE_BOT}/companies/${params.companyId}/bots/${params.botId}/active_training_expressions/${params.expressionId}`)
}

export default {
  getActiveTraining,
  deleteExpressionActiveTraining
}
