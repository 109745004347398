var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "page-active-training internal-page"
  }, [_c('v-layout', [_c('v-row', {
    staticClass: "px-10 active-training-content mt-0"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "pb-1 mt-0",
    staticStyle: {
      "border-bottom": "1px solid #0000007d"
    }
  }, [_c('v-col', {
    staticClass: "pa-0 animate-col",
    attrs: {
      "cols": "".concat(_vm.isSearch ? '0' : '11')
    }
  }, [_c('h3', {
    class: {
      'left': _vm.isSearch
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('activeTraining.title')) + " ")])]), _c('v-col', {
    class: "pa-0 d-flex align-items align-center justify-content ".concat(_vm.isSearch ? 'justify-space-between' : 'justify-end', " animate-col"),
    attrs: {
      "cols": "".concat(_vm.isSearch ? '12' : '1')
    }
  }, [_c('v-icon', {
    staticClass: "icons mr-1",
    attrs: {
      "color": "#282828",
      "size": "28"
    },
    on: {
      "click": _vm.togleIsSearch
    }
  }, [_vm._v(" search ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.search,
      expression: "search"
    }],
    staticClass: "search-content text-input animate-input-in",
    class: {
      'animate-input-out': !_vm.isSearch
    },
    attrs: {
      "placeholder": _vm.$t('activeTraining.searchPlaceholder')
    },
    domProps: {
      "value": _vm.search
    },
    on: {
      "blur": _vm.cleanCloseSearch,
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return function ($event) {
          return $event.target.blur();
        }.apply(null, arguments);
      },
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.search = $event.target.value;
      }
    }
  }), _c('v-icon', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip.auto",
      value: {
        content: _vm.$t('activeTraining.tooltip'),
        offset: 10
      },
      expression: "{ content: $t('activeTraining.tooltip'), offset: 10 }",
      modifiers: {
        "auto": true
      }
    }],
    staticClass: "ml-2 icons",
    attrs: {
      "color": "#282828",
      "size": "28"
    }
  }, [_vm._v(" help ")])], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "legend rounded mb-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('activeTraining.legend'))
    }
  })])], 1), _c('v-row', [_c('v-col', {
    staticClass: "pa-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('p-table', {
    ref: "report",
    attrs: {
      "identifier-field": "idReport",
      "height": _vm.height,
      "headers": _vm.headersList,
      "data-table": _vm.dataResult,
      "config-paginnation": _vm.paginationData,
      "is-loading": _vm.isLoading,
      "is-select": true,
      "action-select": _vm.actionSelect,
      "callback-edit": _vm.addExpression,
      "callback-delete": _vm.deleteExpression,
      "callback-autocomplete-search": _vm.searchIntentions
    },
    on: {
      "select": _vm.newExpressionSelect,
      "actionSelectOption": _vm.newExpressionSelect,
      "page": function page($event) {
        return _vm.ordinationOrPagination($event, 'page');
      },
      "ordination": function ordination($event) {
        return _vm.ordinationOrPagination($event, 'ordination');
      }
    }
  })], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }